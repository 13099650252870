import React from "react";
import TextField from "@mui/material/TextField";
import { red } from "@mui/material/colors";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import { regexNumberCheck } from "../../utils/Common";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      // prefix="$"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const InputTextField = (props) => {
  const handleChange = (e) => {
    if (props.typeText) {
      props.onChange(e);
    } else if (!props.typeText && regexNumberCheck(e.target.value)) {
      props.onChange(e);
    }
  };

  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      if (typeof props.onKeyPressEnter === "function") {
        props.onKeyPressEnter(e);
      }
    }
  };
  return (
    <TextField
      disabled={props.disabled}
      error={!props.validation ? false : true}
      required={props.required}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      name={props.name}
      value={!props.value ? '' : props.value}
      // value={props.value}
      onChange={(e) => handleChange(e)}
      onKeyUp={onKeyPress}
      // variant={props.readOnly ? "filled" : "outlined"}
      variant={props.variant ? "filled" : "outlined"}
      autoComplete={props.autoComplete}
      autoFocus={props.autoFocus}
      select={props.select}
      size="small"
      InputProps={{
        readOnly: props.readOnly,
        inputComponent: props.formatNumber ? NumericFormatCustom : 0 ,
        style: {
          fontSize:props.fontSize ? props.fontSize:"13px",
          height:props.height ? props.height:"35px",
        },
      }}
      inputProps={{
        maxLength: props.maxLength,
      }}
      InputLabelProps={{
        style: {
          fontSize: props.fontSize ? props.fontSize:"12px",
          color: props.required ? red[600] : "#000000",
        },
      }}
      helperText={props.validation}
      sx={
        props.required
          ? {
              m: 1,
              width: props.width,
              // fontWeight:'900',
              "& .MuiInputLabel-root ": { color: red[600] },
              "& .MuiInputLabel-root .Mui-focused": { color: red[600] },
              /* "& .MuiOutlinedInput-root ": {
                " fieldset": { borderColor: red[600] },
                "&.Mui-focused fieldset": { borderColor: red[600] },
                "&:hover fieldset": { borderColor: red[100] }, 
              },*/
              "& .MuiOutlinedInput-input": {
                height: "100%",
                padding: "4px 8px",
                // fontSize: "13px",    
                fontSize: props.fontSizeInput?props.fontSizeInput:"13px",                 
              },
            }
          : {
              m: 1,
              width: props.width,
              // fontWeight:'900',
              "& .MuiOutlinedInput-input": {
                height: "100%",
                padding: "4px 8px",
                // fontSize: "13px",
                fontSize: props.fontSizeInput?props.fontSizeInput:"13px",     
              },
            }
      }
    />
  );
};

InputTextField.defaultProps = {
  width: 160,
  required: false,
  autoComplete: "off",
  disabled: false,
  typeText: true,
  maxLength: 999,
  formatNumber: false,
  select:false,
};

export default React.memo(InputTextField);
